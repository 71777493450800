
import useFormContext from "../../hooks/useFormContext"
import { useState } from "react";

export default function ReviewSubmit() {
  const { data, setData, listData} = useFormContext()

  const [applicantNumber, setApplicantNumber] = useState(0);
  const [expand01, setExpand01] = useState(false);
  const [expand02, setExpand02] = useState(false);
  const [expand03, setExpand03] = useState(false);
  const [expand04, setExpand04] = useState(false);
  const [expand, setExpand] = useState(false);
    
  const handleExpand = (index) => {
    setApplicantNumber(index)
    setExpand(true)
  }

  const deleteNeighbour = (e, idx)=>{
    e.preventDefault();
    setData(prevData => ({ ...prevData,'neighbours': data.neighbours.splice(idx,1)}));    
  }

  const deleteOtherApplicant = (e, idx)=>{
    e.preventDefault();
    setData(prevData => ({ ...prevData,'other_applicants': data.other_applicants.splice(idx,1)}));    
  }

  return (
    <div className="row mb-2">
      {/* *********************Applicant Details********************** */}
      <div className='col-lg-12 title mb-1'>
        <div className='default'>
          {expand01 ? <i className="bi bi-caret-up" onClick={(e)=>setExpand01(false)}/> : <i className="bi bi-caret-down"  onClick={(e)=>setExpand01(true)}/>}
          &nbsp;&nbsp;&nbsp;
          Applicant Details
        </div>
      </div>
      {expand01 && 
        <>
          <div className="col-lg-6 col-md-6 form-group mb-2 px-5"> 
            Who is Applying<br/>
            <b>{data.applicant_category}</b>
          </div>
          <div className="col-lg-6 col-md-6 form-group mb-2 px-5">
            Citizenship<br/>
            <b>
              {listData.listCountries.length>0 && listData.listCountries.map((item, idx)=>{
                return(<span key={item.id}>{ item.id === parseInt(data.citizenship) && item.name}</span>)
              })}
            </b>
          </div>
          <div className="col-lg-6 col-md-6 form-group mb-2 px-5">
            Date of Birth<br/>
            <b>{data.dob}</b>
          </div>
          <div className="col-lg-6 col-md-6 form-group mb-2 px-5">
            {data.citizenship==='1'? 'NIN' : 'Passport Number'}<br/>
            <b>{data.identity_number}</b>
          </div>
          <div className="col-lg-6 col-md-6 form-group mb-2 px-5">
            Surname<br/>
            <b>{ data.surname }</b>
          </div>
          <div className="col-lg-6 col-md-6 form-group mb-2 px-5">
            Other Names<br/>
            <b>{ data.other_names }</b>
          </div>
          <div className="col-lg-6 col-md-6 form-group mb-2 px-5">
            Gender<br/>
            <b>{ data.gender }</b>
          </div>
          <div className="col-lg-6 col-md-6 form-group mb-2 px-5">
            Marital Status<br/>
            <b>{data.marital_status}</b>
          </div>
          <div className="col-lg-6 col-md-6 form-group mb-2 px-5">
            Contact Phone<br/>
            <b>{data.phone_number}</b>
          </div>
          <div className="col-lg-6 col-md-6 form-group mb-2 px-5">
            Contact Address<br/>
            <b>{data.email}</b>
          </div>
        </>
      }
      {/* *********************Start of Other Applicants********************** */}
      { data.other_applicants.length>0 &&
        <>
          <div className='col-lg-12 title mt-3'>
            <div className='default'>
              {expand02 ? <i className="bi bi-caret-up" onClick={(e)=>setExpand02(false)}/> : <i className="bi bi-caret-down"  onClick={(e)=>setExpand02(true)}/>}
              &nbsp;&nbsp;&nbsp;&nbsp;
              Other Applicants' Details
            </div>
          </div>
          {expand02 && data.other_applicants.map((value, idx)=>{
            return (
              <div key={idx} className='position-relative mb-1 text-dark mx-1'>
                <div className='row'>
                  <div className="col-lg-9 bg-white py-2 px-5">
                    {(expand && applicantNumber === idx) ? 
                      <i className="bi bi-caret-up" onClick={(e)=>setExpand(false)}/> 
                      : 
                      <i className="bi bi-caret-down" onClick={(e)=>{setExpand(true); handleExpand(idx)}}/>
                    }&nbsp;
                    <b>{value.surname} {value.other_names}</b> 
                    { value.percentage && '- '+ value.percentage + '%'}&nbsp;&nbsp;
                    <i className="bi bi-pencil-square" aria-label='Edit'/>Edit&nbsp;
                    <i className="bi bi-trash" aria-label='Delete' onClick={(e)=>deleteOtherApplicant(e, idx)}/>Delete
                  </div>
                  {(expand && applicantNumber === idx) && 
                    <div className='row mx-1'>
                      <div className="col-lg-6 col-md-6 form-group mb-2 px-5">
                        Citizenship<br/>
                        <b>
                        {listData.listCountries.length>0 && listData.listCountries.map((item, idx)=>{
                          return(<span key={item.id}>{ item.id === parseInt(value.citizenship) && item.name}</span>)
                        })}
                        </b>
                      </div>
                      <div className="col-lg-6 col-md-6 form-group mb-2 px-5">
                        {value.citizenship==='1'? 'NIN' : 'Passport Number'}<br/>
                        <b>{value.identityNumber}</b>
                      </div>
                      <div className="col-lg-6 col-md-6 form-group mb-2 px-5">
                        Surname<br/>
                        <b>{ value.surname }</b>
                      </div>
                      <div className="col-lg-6 col-md-6 form-group mb-2 px-5">
                        Other Names<br/>
                        <b>{ value.other_names }</b>
                      </div>
                      <div className="col-lg-6 col-md-6 form-group mb-2 px-5">
                        Gender<br/>
                        <b>{ value.gender }</b>
                      </div>
                      <div className="col-lg-6 col-md-6 form-group mb-2 px-5">
                        Marital Status<br/>
                        <b>{ value.marital_status}</b>
                      </div>
                      <div className="col-lg-6 col-md-6 form-group mb-2 px-5">
                        Contact Phone<br/>
                        <b>{ value.phone_number}</b>
                      </div>
                      <div className="col-lg-6 col-md-6 form-group mb-2 px-5">
                        Contact Address<br/>
                        <b>{ value.email}</b>
                      </div>
                    </div>
                  }                 
                </div>
              </div>
            )
          })}
        </>
      }

      {/* ***************************Land Details***************************** */}
      <div className='col-lg-12 title mt-3'>
        <div className='default'>
          {expand03 ? <i className="bi bi-caret-up" onClick={(e)=>setExpand03(false)}/> : <i className="bi bi-caret-down"  onClick={(e)=>setExpand03(true)}/>}
          &nbsp;&nbsp;&nbsp;
          Land Details
        </div>
      </div>
      {expand03 && 
        <>
          <div className="col-lg-6 col-md-6 form-group mb-2 px-5">
            Lease Category<br/>
            <b>{ data.lease_category }</b>
          </div>
          <div className="col-lg-6 col-md-6 form-group mb-2 px-5">
            District<br/>
            <b>
              {listData.listDistricts.length>0 && listData.listDistricts.map((item, idx)=>{
                return(<span key={item.id}>{ item.id === parseInt(data.district) && item.name}</span>)
              })}
            </b>
          </div>
          <div className="col-lg-6 col-md-6 form-group mb-2 px-5">
            County/Municipality<br/>
            <b>
              {listData.listCounties.length>0 && listData.listCounties.map((item, idx)=>{
                return(<span key={item.id}>{ item.id === parseInt(data.county) && item.name}</span>)
              })}
            </b>
          </div>
          <div className="col-lg-6 col-md-6 form-group mb-2 px-5">
            Sub-County/Division<br/>
            <b>
              {listData.listSubcounties.length>0 && listData.listSubcounties.map((item, idx)=>{
                return(<span key={item.id}>{ item.id === parseInt(data.sub_county) && item.name}</span>)
              })}
            </b>
          </div>
          <div className="col-lg-6 col-md-6 form-group mb-2 px-5">
            Parish/Ward<br/>
            <b>
              {listData.listParishes.length>0 && listData.listParishes.map((item, idx)=>{
                return(<span key={item.id}>{ item.id === parseInt(data.parish) && item.name}</span>)
              })}
            </b>
          </div>
          <div className="col-lg-6 col-md-6 form-group mb-2 px-5">
            Village<br/>
            <b>
              {listData.listVillages.length>0 && listData.listVillages.map((item, idx)=>{
                return(<span key={item.id}>{ item.id === parseInt(data.village) && item.name}</span>)
              })}
            </b>
          </div>
          <div className="col-lg-6 col-md-6 form-group mb-2 px-5">
            Acreage<br/>
            <b>{ data.acreage }</b> Ha.
          </div>
          <div className="col-lg-6 col-md-6 form-group mb-2 px-5">
            <b>Land Use Detials</b><br/>
            {data.occupation_of_land}
          </div>
        </>
      }
      
      {/* ***************************Adjacent Land Owners***************************** */}
      <div className='col-lg-12 title mt-3'>
        <div className='default'>
          {expand04 ? <i className="bi bi-caret-up" onClick={(e)=>setExpand04(false)}/> : <i className="bi bi-caret-down"  onClick={(e)=>setExpand04(true)}/>}
          &nbsp;&nbsp;&nbsp;
          Adjacent Land Owners
        </div>
      </div>
      {expand04 &&
        <div className="col-lg-6 col-md-6 form-group mb-2 px-5">
          {data.neighbours.length>0 && data.neighbours.map((item,idx)=>{
            return(
              <div key={idx} className="mb-2">
                <b>{item.surname} {item.other_names}</b> - {item.phone_numbere} &nbsp;&nbsp;
                <i className="bi bi-trash" aria-label='Delete' onClick={(e)=>deleteNeighbour(e, idx)}/>Delete
              </div>)
          })}  
        </div>
      }
    </div>
  )
}
