
export default function ContactUs() {
    return (
        <section className="contact">
            {/** Page Title */}
            <div className="container">
                <div className="section-title mt-3">
                    <h2>Contact Us</h2>
                </div>
                <div className='stage'>
                    <div className='title mb-3'>
                        <div className='default'>Contact Us</div>
                        <div className='rule'></div>
                    </div>
                    <div className="row mb-2">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 form-group">
                                <input className="form-control px-2 mt-1" type="text" name="name" id="name" placeholder="Your Name" required />
                            </div>
                            <div className="col-lg-6 col-md-6 form-group">
                            <input type="email" className="form-control px-2 mt-1" name="email" id="email" placeholder="Your Email" required />
                            </div>
                        </div>
                        <div className="form-group mt-3">
                            <input className="form-control px-2 mt-1" type="text" name="subject" id="subject" placeholder="Subject" required />
                        </div>
                        <div className="form-group mt-3">
                            <textarea className="form-control px-2 mt-1" name="message" rows="10" placeholder="Message" required />
                        </div>
                        <div className="my-3">
                            <div className="loading">Loading</div>
                            <div className="error-message"></div>
                            <div className="sent-message">Your message has been sent. Thank you!</div>
                        </div>
                        <div className="text-center button-container"><button className="btn-stage btn-next btn-text button" type="submit">SEND</button></div>
                    </div>
                    <div className="col-lg-2 col-md-2"></div>
                </div>
            </div>
        </section>
    )
}
