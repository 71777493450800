import useFormContext from "../../hooks/useFormContext"
import { useLocation } from 'react-router-dom'
import { useEffect, useState } from "react"
import axios from "../../assets/axios"

const NINREGEX = /^C[MF][A-Z0-9]{12}$/
const EMAILREGEX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
const PHONEREGEX = /^0[2347]\d{8}$/

export default function ApplicantDetails() {

  const { data, setData, listData, setListData, handleChange } = useFormContext()
  const [errorEmail, setErrorEmail] = useState(false)
  const [errorPhone, setErrorPhone] = useState(false)
  const [errorNIN, setErrorNIN] = useState(false)
  const [resumeDOB, setResumeDOB] = useState('')
  const [resumeID, setResumeID] = useState('')
  const [resumeSQ, setResumeSQ] = useState('')
  const [resumeSA, setResumeSA] = useState('')
  const [loadForm, setloadForm] = useState(false)

  const location = useLocation();
  const activeLocation = location?.pathname.split('/').pop();

  useEffect(()=>{
      setData(prevData => ({ ...prevData,'step': activeLocation}))
  },[activeLocation, setData])

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    axios.get('/common/citizenships/').then((response) => {
      isMounted && setListData(prevData => ({ ...prevData,'listCountries': response.data.data.results}))}).catch((error) => {
        //Handle Errors Coming out of this
    });
    axios.get('/common/security_questions/').then((response) => {
      isMounted && setListData(prevData => ({ ...prevData,'listSQs': response.data.data.results}))}).catch((error) => {
        //Handle Errors Coming out of this
    });
    return () => { isMounted = false; controller.abort(); }
    // eslint-disable-next-line
  
  }, [setListData]); 

  useEffect(() => {
    if(resumeDOB && resumeID && resumeSQ && resumeSA){
      let isMounted = true;
      const controller = new AbortController();
      axios.get('/application/lease/?dob=' + resumeDOB  + '&nin=' + resumeID + '&security_question=' + resumeSQ + '&security_answer=' + resumeSA).then((response) => {
        if(isMounted){
          setData(response.data.data)
        }else{
          console.log('Not Mounted')
        }
      }).catch(function(error){
        console.log(error.status);
        setErrorNIN(true)
      });
      
        //Handle Errors Coming out of this
      return () => { isMounted = false; controller.abort(); }
      // eslint-disable-next-line
    }
  }, [resumeDOB, resumeID, resumeSQ, resumeSA, setData]);

  const validateField = (e)=>{
    e.preventDefault();
    if(e.target.name==='identity_number'){
      if(NINREGEX.test(e.target.value)){
        fetchNIN(e.target.value)
        setErrorNIN(false)
      }else{
        setErrorNIN(true)
      }
    }else if(e.target.name==='email'){
      if(EMAILREGEX.test(e.target.value)){
        handleChange(e)
        setErrorEmail(false)
      }else{
        setErrorEmail(true)
      }
    }else if(e.target.name==='phone_number'){
      if(PHONEREGEX.test(e.target.value)){
        handleChange(e)
        setErrorPhone(false)
      }else{
        setErrorPhone(true)
      }
    }
  }
  
  const fetchNIN = async (e) => {
    const NINResult = await axios.get('/application/search-person/?nin=' + e)
    setData(prevData => ({ ...prevData,'surname': NINResult.data.data.surname}))
    setData(prevData => ({ ...prevData,'other_names': NINResult.data.data.given_name}))
    setData(prevData => ({ ...prevData,'gender': NINResult.data.data.gender}))
    setData(prevData => ({ ...prevData,'identity_number': e}))
  }

  useEffect(()=>{
    if(data.citizenship !== '1' && (data.identity_number && data.dob)){
      setloadForm(true);
    }else if(data.citizenship==='1' && data.surname){
      setloadForm(true);
    }
  }, [data.citizenship, data.identity_number, data.dob, data.surname] )

  return (
    <div className="row mb-2">
      {(data.step==='resume' & data.id==='') ? 
        <>
          <div className="col-lg-6 col-md-6 form-group mb-2">
            <label htmlFor="resumeDOB">Date of Birth*</label>
            <input className="form-control px-2 mt-1" type="date" name="resumeDOB" onChange={(e)=>setResumeDOB(e.target.value)} required/>
          </div>
          <div className="col-lg-6 col-md-6 form-group mb-2">
            <label htmlFor="resumeID">NIN/Passport Number*</label>
            <input className="form-control px-2 mt-1" type="text" name="resumeID" onChange={(e)=>setResumeID(e.target.value)} required/>
          </div>
          <div className="col-lg-6 col-md-6 form-group mb-2">
            <label htmlFor='resumeSQ'>Security Question*</label>
            <select className="form-control px-2 mt-1" name='resumeSQ' id='resumeSQ' onChange={(e)=>setResumeSQ(e.target.value)} required>
              <option>Select Your Security Question</option>
              {listData.listSQs.length>0 && listData.listSQs.map((sQ, idx) => {
                return(<option key={sQ.id} value={sQ.id}>{sQ.question}</option>)
              })}
            </select>
          </div>
          <div className="col-lg-6 col-md-6 form-group mb-2">
            <label htmlFor="resumeSA"></label>Security Answer*
            <input className="form-control px-2 mt-1" type="text" placeholder="Your answer to the security question here.." name="resumeSA" onChange={(e)=>setResumeSA(e.target.value)} required />
          </div>     
        </> 
      : 
        <>
          <div className="col-lg-6 col-md-6 form-group mb-2">
            <label htmlFor='applicant_category'>Who is Applying</label>
            <select className="form-control px-2 mt-1" name='applicant_category' id='applicant_category' defaultValue={data.applicant_category} onChange={handleChange} required>
              <option>Select Applicant Category</option>
              <option value='Individual'>Individual</option>
              <option value='Community'>Community</option>
              <option value='Company'>Company</option>
            </select>
          </div>
          <div className="col-lg-6 col-md-6 form-group mb-2">
            <label htmlFor='citizenship'>Applicant Citizenship*</label>
            <select className="form-control px-2 mt-1" name='citizenship' id='citizenship' defaultValue={data.citizenship} onChange={handleChange} required>
              <option>Select Country</option>
              {listData.listCountries.length>0 && listData.listCountries.map((cntry, idx) => {
                return(<option key={cntry.id} value={cntry.id}>{cntry.name}</option>)
              })}
            </select>      
          </div>
          {(data.applicant_category && data.citizenship) &&
            <>
              <div className="col-lg-6 col-md-6 form-group mb-2">
                Date of Birth*
                <input className="form-control px-2 mt-1" type="date" name="dob" value={data.dob} onChange={handleChange} required/>
              </div>
              {data.citizenship === '1' ? 
                <div className="col-lg-6 col-md-6 form-group mb-2">
                  NIN*
                  <input className="form-control px-2 mt-1" type="text" name="identity_number" defaultValue={data.identity_number} onChange={validateField} required />
                  {errorNIN && <span><b><i className='bi bi-exclamation-circle'/></b> Mising or incorrect NIN</span>}
                </div>
              : 
                <div className="col-lg-6 col-md-6 form-group mb-2">
                  Passport Number*
                  <input className="form-control px-2 mt-1" type="text" name="identity_number" value={data.identity_number} onChange={(e)=>handleChange(e)} required />
                </div>
              }
              { loadForm &&
                <>
                  <div className="col-lg-6 col-md-6 form-group mb-2">
                    Surname*
                    { data.citizenship === '1' ?
                      <input className="form-control px-2 mt-1" type="text" name="surname" value={data.surname} disabled required/>
                      :
                      <input className="form-control px-2 mt-1" type="text" name="surname" value={data.surname} onChange={handleChange} required />
                    }
                  </div>
                  <div className="col-lg-6 col-md-6 form-group mb-2">
                    Other Names*
                    { data.citizenship === '1' ?
                      <input className="form-control px-2 mt-1" type="text" name="other_names" value={data.other_names} disabled required />
                      :
                      <input className="form-control px-2 mt-1" type="text" name="other_names" value={data.other_names} onChange={handleChange} required />
                    }
                  </div>
                  <div className="col-lg-6 col-md-6 form-group mb-2">
                    <label htmlFor='gender'>Gender*</label>
                    { data.citizenship === '1' ?
                      <input className="form-control px-2 mt-1" type='text' name='gender' value={data.gender} disabled/>
                      :
                      <select className="form-control px-2 mt-1" name='gender' id='gender' onChange={handleChange} required>
                        <option>Select Gender</option>
                        <option value='female'>Female</option>
                        <option value='male'>Male</option>
                      </select>  
                    }
                  </div>
                  <div className="col-lg-6 col-md-6 form-group mb-2">
                    <label htmlFor='marital_status'>Marital Status*</label>
                    <select className="form-control px-2 mt-1" name='marital_status' id='marital_status' defaultValue={data.marital_status} onChange={handleChange} required>
                      <option>Select Marital Status</option>
                      <option value='Single'>Single</option>
                      <option value='Married'>Married</option>
                      <option value='Widowed'>Widowed</option>
                      <option value='Divorced'>Divorced</option>
                    </select>
                  </div>
                  <div className="col-lg-6 col-md-6 form-group mb-2">
                      Contact Phone*
                    <input className="form-control px-2 mt-1" type="text" name="phone_number" defaultValue={data.phone_number} onChange={validateField} required />
                    {errorPhone && <span><b><i className='bi bi-exclamation-circle'/></b> Mising or incorrect Number. Use format 0XXX XXX</span>}
                  </div>
                  <div className="col-lg-6 col-md-6 form-group mb-2">
                    Email Address*
                    <input className="form-control px-2 mt-1" type="email" name="email" defaultValue={data.email} onChange={validateField} required />
                    {errorEmail && <span><b><i className='bi bi-exclamation-circle'/></b> Mising or incorrect email address</span>}
                  </div>
                  <div className="col-lg-6 col-md-6 form-group mb-2">
                    <label htmlFor='security_question'>Security Question*</label>
                    <select className="form-control px-2 mt-1" name='security_question' id='security_question' defaultValue={data.security_question} onChange={handleChange} required>
                      <option>Select Security Question</option>
                      {listData.listSQs.length>0 && listData.listSQs.map((sQ, idx) => {
                        return(<option key={sQ.id} value={sQ.id}>{sQ.question}</option>)
                      })}
                    </select>
                  </div>
                  <div className="col-lg-6 col-md-6 form-group mb-2">
                    Security Answer*
                    <input className="form-control px-2 mt-1" type="text" placeholder="Answer to Security Question" name="security_answer" value={data.security_answer} onChange={(e)=>handleChange(e)} required />
                  </div>            
                </>
              }
            </>
          }
        </>
      }
    </div>
  )
}
