import FormInputs from "../modules/Application/FormInputs"
import ApplicationProgress from "./ApplicationProgress"
import useFormContext from "../hooks/useFormContext"
import { useNavigate } from "react-router-dom"

const Form = () => {

    const navigate = useNavigate();
    const { page, setPage, title, noSubmit, disablePrev, disableNext, 
            prevHide, nextHide, submitHide, cancelHide, handleSubmit
    } = useFormContext()

    const handlePrev = () => setPage(prev => prev - 1)
    
    const handleCancel = () => navigate('/')

    const handleNext = () => setPage(prev => prev + 1)

    const content = (
        <>
            <ApplicationProgress page={page}/>
            <div className="stage">
                <div className='title mb-3'>
                    <div className='default'>{title[page]}</div>
                    <div className='rule'></div>
                </div>
                <FormInputs/>
                <div className="button-container mt-3 mb-3">
                    <button type="button" className={`btn-stage btn-previous btn-text button ${prevHide}`} disabled={disablePrev} onClick={handlePrev}>PREVIOUS</button>
                    &nbsp;&nbsp;
                    <button type="button" className={`btn-stage btn-cancel btn-text button ${cancelHide}`} onClick={handleCancel}>CANCEL</button>
                    &nbsp;&nbsp;
                    <button type="button" className={`btn-stage btn-next btn-text button ${nextHide}`} disabled={disableNext} onClick={(e)=>{handleNext(); handleSubmit()}}>NEXT</button>
                    &nbsp;&nbsp;
                    <button type="submit" className={`btn-stage btn-next btn-text button ${submitHide}`} disabled={noSubmit} onClick={(e)=>handleSubmit()}>SUBMIT</button>
                </div>
            </div>
        </>
    )

    return content
}

export default Form