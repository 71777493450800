import Navbar from "./Navbar";
import NavFlag from "./NavFlag";
import TopNav from "./TopNav";

export default function Topbar() {
  return (
    <>
      <TopNav />
      <NavFlag />
      <Navbar />
    </>
  )
}