import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';


export default function Navbar() {

  const location = useLocation();
  const[menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () =>{
    setMenuOpen(!menuOpen)
  }
  let activeLocation = location?.pathname.split('/').pop(); 
  let activeNav = '';
    
  if (activeLocation === '') {
      activeNav = 'home';
  } else if(activeLocation === 'resume') {
      activeNav = 'resume';
  } else if(activeLocation === 'retrieve') {
      activeNav = 'retrieve';
  } else if(activeLocation === 'Option1') {
    activeNav = 'status';
  } else if(activeLocation === 'Option2') {
    activeNav = 'status';
  } else if(activeLocation === 'contact') {
    activeNav = 'contact';
  }
  return (
    <nav id="navbar" className="navbar sticky-top">
      <ul className={menuOpen ? 'navbar active' : 'navbar'}>
        <li><b><Link className={activeNav ==='home' ? 'active nav-link' : 'nav-link'} to="/">Home</Link></b></li>
        <li><b><Link className={activeNav ==='resume' ? 'active nav-link' : 'nav-link'} to="/apply/resume">Resume Application</Link></b></li>
        <li><b><Link className={activeNav ==='retrieve' ? 'active nav-link' : 'nav-link'} to="/retrieve">Retrieve Assessment</Link></b></li>
        <li className="dropdown"><b><Link to="#status" className={activeNav ==='status' ? 'active nav-link' : 'nav-link'}><span>Check Status</span>&nbsp;<span style={{fontSize: '10px', paddingTop: '4px'}}>&#x25bc;</span>{/* <i className="bi bi-chevron-down"></i>*/}</Link></b>
          <ul>
            <li><b><Link className={activeLocation ==='Option1' ? 'active nav-link' : 'nav-link'} to="/Option1">Drop Down 1</Link></b></li>
            <li><b><Link className={activeLocation ==='Option2' ? 'active nav-link' : 'nav-link'} to="/Option2">Drop Down 2</Link></b></li>
          </ul>
        </li>
        <li><b><Link className={activeNav ==='contact' ? 'active nav-link' : 'nav-link'} to="/contact">Contact</Link></b></li>
      </ul>
      <i className={menuOpen ? "bi bi-x-lg" : "bi bi-list"} onClick={toggleMenu} />
    </nav>
  )
}
