import CoArms from '../assets/img/coatofarms.png'
import ULC from '../assets/img/headerwords.png'
import Logo from '../assets/img/ulclogo.png'
import { Link } from 'react-router-dom'

export default function TopNav() {
  return (
    <div id="topbar" className="topbar">
        <div className='left'>
          <Link to='/'><img src={Logo} alt='' /></Link>
        </div>
        <div className='main'>
          <img src={ULC} alt='' />
        </div>
        <div className='right'>
          <img src={CoArms} alt='' />
        </div>
    </div>
  )
}
