import ApplicationProgress from "../components/ApplicationProgress";

export default function Form() {
  return (
    <section>
      <div className="container">
        {/** Page Title */}
        <div className="section-title mt-3">
          <h2>New Lease Applicaton</h2>
        </div>
        {/** Progress breadcrumb */}
        <ApplicationProgress />
        {/* Form Details contained here */}
        {/** Form Staging Area */}
        <div className="stage">
          <div className='title'>
            <div className='default'>Applicant Details</div>
            <div className='rule'></div>
          </div>
          <div className="mt-3">
            <form>
              <div className="row mb-2">
              <div className="col-lg-6 col-md-6 form-group mb-2">
                  Who is Applying
                  <input type="text" name="name" className="form-control p-0 mt-1" id="name" placeholder="" required />
                </div>
                <div className="col-lg-6 col-md-6 form-group mb-2">
                  Citizenship
                  <input type="email" className="form-control p-0 mt-1" name="email" id="email" placeholder="" required />
                </div>
                <div className="col-lg-6 col-md-6 form-group mb-2">
                  Who is Applying
                  <input type="text" name="name" className="form-control p-0 mt-1" id="name" placeholder="" required />
                </div>
                <div className="col-lg-6 col-md-6 form-group mb-2">
                  Citizenship
                  <input type="email" className="form-control p-0 mt-1" name="email" id="email" placeholder="" required />
                </div>
                <div className="col-lg-6 col-md-6 form-group mb-2">
                  Who is Applying
                  <input type="text" name="name" className="form-control p-0 mt-1" id="name" placeholder="" required />
                </div>
                <div className="col-lg-6 col-md-6 form-group mb-2">
                  Citizenship
                  <input type="email" className="form-control p-0 mt-1" name="email" id="email" placeholder="" required />
                </div>
                <div className="col-lg-6 col-md-6 form-group mb-2">
                  Who is Applying
                  <input type="text" name="name" className="form-control p-0 mt-1" id="name" placeholder="" required />
                </div>
                <div className="col-lg-6 col-md-6 form-group mb-2">
                  Citizenship
                  <input type="email" className="form-control p-0 mt-1" name="email" id="email" placeholder="" required />
                </div>
                <div className="col-lg-6 col-md-6 form-group mb-2">
                  Who is Applying
                  <input type="text" name="name" className="form-control p-0 mt-1" id="name" placeholder="" required />
                </div>
                <div className="col-lg-6 col-md-6 form-group mb-2">
                  Citizenship
                  <input type="email" className="form-control p-0 mt-1" name="email" id="email" placeholder="" required />
                </div>
                <div className="col-lg-6 col-md-6 form-group mb-2">
                  Who is Applying
                  <input type="text" name="name" className="form-control p-0 mt-1" id="name" placeholder="" required />
                </div>
                <div className="col-lg-6 col-md-6 form-group mb-2">
                  Citizenship
                  <input type="email" className="form-control p-0 mt-1" name="email" id="email" placeholder="" required />
                </div>
              </div>
              <div className="row">
                <div className="btn-left col-6">
                  <button type="submit" className='btn-stage btn-cancel btn-text'>PERSON 1 x<i className='bi bi-close' /></button>
                  <button type="submit" className='btn-stage btn-cancel btn-text'>PERSON 2 x</button>   
                </div>
                <div className="btn-right col-6">
                  <button type="submit" className='btn-stage btn-other btn-text'>Add Person</button>
                  <button type="submit" className='btn-stage btn-other btn-text'>Update</button>   
                </div>
              </div>
              <div className='btn-right mt-5 mb-3'>
                <button type="submit" className='btn-stage btn-previous btn-text'>PREVIOUS</button>&nbsp;&nbsp;
                <button type="submit" className='btn-stage btn-next btn-text'>NEXT</button>&nbsp;&nbsp;
                <button type="submit" className='btn-stage btn-cancel btn-text'>CANCEL</button>                   
              </div>
            </form>
          </div>
        </div> 
      </div>
    </section>
  )
}
