import MoreApplicantDetails from './MoreApplicationDetails'
import useFormContext from "../../hooks/useFormContext"
import ApplicationDetails from "./ApplicantDetails"
import AdjacentLand from './AdjacentLand'
import ReviewSubmit from './ReviewSubmit'
import LandDetails from './LandDetails'

const FormInputs = () => {

    const { page } = useFormContext()
    
    const display = {
        0: <ApplicationDetails/>,
        1: <MoreApplicantDetails/>,
        2: <LandDetails/>,
        3: <AdjacentLand/>,
        4: <ReviewSubmit/>
    }

    const content = (
        <div className=''>
            {display[page]}
        </div>
    )

    return content
}
export default FormInputs