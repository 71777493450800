
export default function ApplicationProgress({page}) {

  const BreadCrumbList = [
    {bcPage: '1', bcTitle: 'Applicant Details'},
    {bcPage: '2', bcTitle: 'More Applicant Details'},
    {bcPage: '3', bcTitle: 'Land Details'},
    {bcPage: '4', bcTitle: 'Adjacent Land Owners'},
    {bcPage: '5', bcTitle: 'Review & Submit'}
  ]
  return (
    <div className='container'>
      <div className="row form-progress mb-2">
          <div className="mb-3">
            <ul>
              { BreadCrumbList.map((value, idx)=>{
                let icon = ''
                let pgState = ''
                if(idx < page){
                  icon = <i className="bi bi-check-circle mx-1"/>
                  pgState = 'after'
                }else if (idx === page){
                  icon = <i className={`bi bi-${value.bcPage}-circle-fill mx-1`}/>
                  pgState = 'active'
                }else{
                  icon = <i className={`bi bi-${value.bcPage}-circle mx-1`}/>
                  pgState = 'before'
                }
                return(
                  
                  <li key={idx} className={pgState}>
                    {icon} 
                    <span className="form-progress-text pb-1">
                      {value.bcTitle}
                    </span>
                    <div className='rule'></div>
                  </li>
                )
              })}
            </ul>
          </div>
      </div>
  </div>
  )
}
