import { Link } from 'react-router-dom';
import Pay from '../assets/img/pay.png';
import Map from '../assets/img/map.png';

export default function Home() {
  return (
    <section className="hero">
      <div className='container'>
        <div className="section-title mt-3">
          <h2>Select A Service</h2>
        </div>
        <div className='row'> {/** Staging Area */}
          <div className="col-lg-4 col-md-4 mb-3">
            <div className="box">
              <h6>Start New Lease Application</h6>
              <img src={Map} alt='' /><br/>
              <Link to="/apply/new" className="btn-home">Apply</Link>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 mb-3">
            <div className="box">
              <h6>Resume Lease Application</h6>
              <img src={Map} alt='' /><br/>
              <Link to="/apply/resume" className="btn-home">Resume</Link>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 mb-3">
            <div className="box">
              <h6>Payments on Existing Lease</h6>
              <img src={Pay} alt='' /><br/>
              <Link to="#link" className="btn-home">Access</Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
