import { Route, Routes } from 'react-router-dom';

//Assets
import { AuthProvider } from './context/AuthProvider';

//Components
import PersistLogin from './components/PersistLogin';
import Bottombar from './components/Bottombar';
import Layout from './components/Layout';
import Topbar from './components/Topbar';

//Modules
import Form from './modules/Form';
import Home from './modules/Home';
import Application from './modules/Application';
import ContactUs from './modules/ContactUs';

export default function App() {
  return (
    <AuthProvider>
        <Topbar />
        <Routes>
          <Route element={<PersistLogin />}>
            <Route path="/" element={<Layout />}>
              <Route exact path='/' element={<Home />}/>
              <Route exact path='/apply/*' element={<Application />}/>
              {/* <Route exact path='/resume' element={<Application />}/> */}
              <Route exact path='/retrieve' element={<Form />}/>
              <Route exact path='/Option1' element={<Form />}/>
              <Route exact path='/Option2' element={<Form />}/>
              <Route exact path='/contact' element={<ContactUs />}/>
            </Route>
          </Route>
        </Routes>
        <Bottombar />
    </AuthProvider>
  );
}
