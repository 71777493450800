import useRefreshToken from '../hooks/useRefreshToken';
import { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const PersistLogin = () => {
    const [isLoading, setIsLoading] = useState(true);
    const refresh = useRefreshToken();
    const { auth } = useAuth();

    useEffect(() => {
        let isMounted = true;
        const verifyRefreshToken = async () => {
            try { 
                await refresh();
            } catch (err) { //console.error(err); 
            } finally { isMounted && setIsLoading(false); }
        }
        !auth?.accessToken ? verifyRefreshToken() : setIsLoading(false);
        return () => isMounted = false;
        // eslint-disable-next-line
    }, [])

    return ( <> { isLoading ? <div className="preloader">
    <div className="loader rubix-cube">
        <div className="layer layer-1"></div>
        <div className="layer layer-2"></div>
        <div className="layer layer-3 color-1"></div>
        <div className="layer layer-4"></div>
        <div className="layer layer-5"></div>
        <div className="layer layer-6"></div>
        <div className="layer layer-7"></div>
        <div className="layer layer-8"></div>
    </div>
</div> : <Outlet /> } </> )
}

export default PersistLogin