
import useFormContext from "../../hooks/useFormContext"
import { useState, useEffect } from "react"
import axios from "../../assets/axios"

const ACREAGE_REGEX = /^[+-]?(\d+(\.\d*)?|\.\d+)$/

export default function LandDetails() {
  const { data, listData, setListData, handleChange } = useFormContext()
  const [errorAcreage, setErrorAcreage] = useState(false)
  
  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    axios.get('/common/districts/').then((response) => {
        if(isMounted){
          setListData(prevData => ({ ...prevData,'listDistricts': response.data.data.results}))
        }
        //console.log(response)
    }).catch((error) => {
        //Handle Errors Coming out of this
    });
    return () => { isMounted = false; controller.abort(); }
    // eslint-disable-next-line
  }, []);

  const fetchCounties = async () => {
    try {    
      const countyResult = await axios.get('/common/counties/?district_id=' + data.district)
      setListData(prevData => ({ ...prevData,'listCounties': countyResult.data.data.results}))
      setListData(prevData => ({ ...prevData,'listSubcounties': ''}))
      setListData(prevData => ({ ...prevData,'listParishes': ''}))
      setListData(prevData => ({ ...prevData,'listVillages': ''}))
    } catch (error) {
      console.log(error)
    }
  }

  const fetchSubcounties = async () => {
    const subcountyResult = await axios.get('/common/sub_counties/?county_id=' + data.county)
    setListData(prevData => ({ ...prevData,'listSubcounties': subcountyResult.data.data.results}))
  }

  const fetchParishes = async () => {
    const parishResult = await axios.get('/common/parishes/?sub_county_id=' + data.subcounty)
    setListData(prevData => ({ ...prevData,'listParishes': parishResult.data.data.results}))
  }

  const fetchVillages = async () => {
    const villageResult = await axios.get('/common/villages/?parish_id=' + data.parish)
    setListData(prevData => ({ ...prevData,'listVillages': villageResult.data.data.results}))
  }

  const validateField = (e)=>{
    e.preventDefault();
    if(e.target.name==='approximate_size' && ACREAGE_REGEX.test(e.target.value)){
      handleChange(e)
      setErrorAcreage(false)
    }else{
      setErrorAcreage(true)
    }
  }

  return (
    <div className="row form-group mb-2">
      <div className="col-lg-6 col-md-6 form-group mb-2">
        Lease Category
        <label htmlFor='lease_category'>Lease Category</label>
          <select className="form-control px-2 mt-1" name='lease_category' id='lease_category' defaultValue={data.lease_category} onChange={handleChange} required>
            <option>Select Lease Category</option>
            <option value='Pool House'>Pool House</option>
            <option value='Ordinary Lease'>Ordinary Lease</option>
          </select>
      </div>
      <div className="col-lg-6 col-md-6 form-group mb-2">
        <label htmlFor="district">District*</label>
        <select name="district" id='district' className="form-control p-2 mt-1" defaultValue={data.district} onChange={(e)=>{ handleChange(e); fetchCounties()}}>
          <option>Select District</option>
          {listData.listDistricts.length>0 && listData.listDistricts.map((district, idx)=>{
            return (<option key={district.id} value={district.id} label={district.name}>{district.name}</option>)
          })}
        </select>
      </div>
      <div className="col-lg-6 col-md-6 form-group mb-2">
        <label htmlFor="county">County/Municipality*</label>
        <select className="form-control p-2 mt-1" name="county" id='county' defaultValue={data.county} onChange={(e)=>{ handleChange(e); fetchSubcounties()}}>
          <option>Select County</option>
          {listData.listCounties && listData?.listCounties.map((county, idx)=>{
            return (<option key={county.id} value={county.id} label={county.name}>{county.name}</option>)
          })}
        </select>
      </div>
      <div className="col-lg-6 col-md-6 form-group mb-2">
        <label htmlFor="sub_county">Subcounty/Division*</label>
        <select className="form-control p-2 mt-1" name="sub_county" id='sub_county' defaultValue={data.sub_county} onChange={(e)=>{ handleChange(e); fetchParishes()}}>
          <option>Select Sub-County</option>
          {listData?.listSubcounties.length>0 && listData?.listSubcounties.map((subcounty, idx)=>{
            return (<option key={subcounty.id} value={subcounty.id} label={subcounty.name}>{subcounty.name}</option>)
          })}
        </select>
      </div>
      <div className="col-lg-6 col-md-6 form-group mb-2">
        <label htmlFor="parish">Parish/Ward*</label>
          <select className="form-control p-2 mt-1" name="parish" id='parish' defaultValue={data.parish} onChange={(e)=>{ handleChange(e); fetchVillages()}}>
          <option>Select Parish</option>
          {listData?.listParishes.length>0 && listData?.listParishes.map((parish, idx)=>{
            return (<option key={parish.id} value={parish.id} label={parish.name}>{parish.name}</option>)
          })}
        </select>
      </div>
      <div className="col-lg-6 col-md-6 form-group mb-2">
        <label htmlFor="village">Village</label>
        <select className="form-control p-2 mt-1" name="village" id='village' defaultValue={data.village} onChange={handleChange}>
          <option>Select Village</option>
          {listData?.listVillages.length>0 && listData?.listVillages.map((village, idx)=>{
            return (<option key={village.id} value={village.id} label={village.name}>{village.name}</option>)
          })}
        </select>
      </div>
      <div className="col-lg-6 col-md-6 form-group mb-2">
        Approximate Area in Hectares*
        <input className="form-control px-2 mt-1" type="text" name="approximate_size" defaultValue={data.approximate_size} onChange={validateField} required />      
        {errorAcreage && <span><b><i className='bi bi-exclamation-circle'/></b> Mising or incorrect Acreage measurement</span>}</div>
      <div className="col-lg-6 col-md-6 form-group mb-2">
        Land Use Details<br/>
        <textarea className="form-control p-2 mt-1" placeholder='Type property details here.....' name="occupation_of_land" style={{minHeight: '115px'}} value={data.occupation_of_land} onChange={handleChange} />
      </div>
    </div>
  )
}
