import ResumeApplication from "./Application/ResumeApplication"
import NewApplication from "./Application/NewApplication"
import { Route, Routes } from "react-router-dom"

export default function Application() {
  return (
      <section>
        {/** Page Title */}
        <div className="section-title mt-3">
          <h2>New Lease Applicaton</h2>
        </div>
        <div className="container">
        <Routes>
            <Route exact path='/new' element={<NewApplication />}/>
            <Route exact path='/resume' element={<ResumeApplication />}/>
        </Routes>
        </div>
      </section>
  )
}
