
import useFormContext from "../../hooks/useFormContext"
import { useState } from "react"

const PHONEREGEX = /^0[2347]\d{8}$/

export default function AdjacentLand() {
  const { data, setData } = useFormContext()

  const [neighbourOthernames, setNeighbourOthernames] = useState('')
  const [errorPhone, setErrorPhone] = useState(false);
  const [addNeighbour, setAddNeighbour] = useState(false)
  const [neighbourPhone, setNeighbourPhone] = useState('')
  const [neighbourSurname, setNeighbourSurname] = useState('')

  const validateField = (e)=>{
    e.preventDefault();
    if(e.target.name==='neighbourPhone' && PHONEREGEX.test(e.target.value)){
        setNeighbourPhone(e.target.value)
        setErrorPhone(false)
      }else{
        setErrorPhone(true)
    }
  }

  const handleNewNeighbour = (e)=>{
    e.preventDefault();
    const newNeighbour = {
      surname: neighbourSurname, 
      other_names: neighbourOthernames,
      phone_number: neighbourPhone
    }
    setData(prevData => ({ ...prevData,'neighbours': [...data.neighbours, newNeighbour]}))
    
    setAddNeighbour(false);
  }

  const deleteNeighbour = (e, idx)=>{
    e.preventDefault();
    setData(prevData => ({ ...prevData,'neighbours': data.neighbours.splice(idx,1)}))
  }

  return (
    <div className="row mb-2">
      {data.neighbours.length>0 && 
        <>
          <div className="row mb-2">
            <div className="col-lg-4">Surname Name</div>
            <div className="col-lg-3">Other Names</div>
            <div className="col-lg-3">Phone Number</div>
            <div className="col-lg-2">Action</div>
          </div>
          { data.neighbours.map((item,idx)=>{
            return(
              <div key={idx} className='row'>
                <div className="col-lg-4 form-group mb-2">
                  <input className="form-control px-2 mt-1" type="text" name="neighbourSurname" value={item.surname} disabled />
                </div>
                <div className="col-lg-3 form-group mb-2">
                  <input className="form-control px-2 mt-1" type="text" name="neighbourOthernames" value={item.other_names} disabled />
                </div>
                <div className="col-lg-3 form-group mb-2">
                  <input className="form-control px-2 mt-1" type="text" name="neighbourPhone" value={item.phone_number} disabled />
                </div>
                <div className="col-lg-2 mt-2 mb-2 p-2">
                  <i className="bi bi-trash" aria-label='Delete' onClick={(e)=>deleteNeighbour(e, idx)}/>Delete
                </div>
              </div>
          )})}
          <div className="m-2">
            &nbsp;
          </div>
        </>
      }

      { (addNeighbour || data.neighbours.length<1 ) && 
        <div className="row mb-2">
          <div className="col-lg-4 form-group mb-2">
            Surname*
            <input type="text" name="neighbourSurname" className="form-control px-2" onChange={(e)=>setNeighbourSurname(e.target.value)} required />
          </div>
          <div className="col-lg-3 form-group mb-2">
            Other Names*
            <input type="text" name="neighbourOthernames" className="form-control px-2" onChange={(e)=>setNeighbourOthernames(e.target.value)} required />
          </div>
          <div className="col-lg-3 form-group mb-2">
            Phone Number*
            <input type="text" name="neighbourPhone" className="form-control px-2" onChange={validateField} required />
            {errorPhone && <span><b><i className='bi bi-exclamation-circle'/></b> Missing or incorrect Phone Number</span>}
          </div>
          <div className="col-lg-2 mb-2 pt-1 mt-3 button-container">
          <button type="button" className={`btn-stage btn-next btn-text button`} onClick={(e)=>handleNewNeighbour(e)}>Add</button>
          </div>
        </div>
      }
      
      <div className='button-container mt-3 mb-3'>
        {(data.neighbours.length>0 && !addNeighbour)  && 
          <button 
            type="button" 
            className={`btn-stage btn-other btn-text button`} 
            onClick={(e)=>setAddNeighbour(true)}
            disabled={!neighbourSurname || !neighbourOthernames || !neighbourPhone } 
          >
            Add Another
          </button>
        }
      </div>
    </div>           
  )
}
