import { createContext, useState } from "react"
// import axios from "../assets/axios"

const FormContext = createContext({})

export const FormProvider = ({ children }) => {

    const title = {
        0: 'Applicant Details',
        1: 'More Application Details',
        2: 'Land Details',
        3: 'Adjacent Land Owners',
        4: 'Review and Submit'
    }
    let newLeaseApplication = {}
    
    const [page, setPage] = useState(0)
    const [listData, setListData] = useState({
        listCountries: '', listSQs: '', listDistricts: '', 
        listCounties: '', listSubcounties: '', listParishes: '', listVillages: ''
    });

    const [data, setData] = useState({
        id:'', applicant_category: '', citizenship: '', dob: '', identity_number: '', 
        community_name: '', community_location: '', company_name: '', 
        company_registration_number: '', surname: '',  other_names: '', gender: '', 
        marital_status: '', phone_number: '', email: '', security_question: '', 
        security_answer: '', applicant_subcategory: '', lease_category: '', district: '', 
        county: '', sub_county: '', parish: '', village: '', approximate_size: '', 
        occupation_of_land: '',  other_applicants: [], neighbours: [], status: '', step: '' 
    })

    // Check if all fields on page 0 have been filled in.
    // Used to enable/diable Next Page button
    function pg0Complete(data) {
        return !data.applicant_category || !data.citizenship || !data.dob || 
               !data.identity_number || !data.surname || !data.other_names || 
               !data.gender || !data.marital_status || !data.phone_number  || 
               !data.email || !data.security_question || !data.security_answer;
    }

    // Check if all fields on page 1 have been filled in.
    // Used to enable/diable Next Page button
    function pg1Complete(data) {
        if(page===1 && data.applicant_category==='Community'){
            return !data.community_name || !data.community_location
        } else if (page===1 && data.whoApplied==='Company'){
            return !data.company_name || !data.company_registration_number;
        }else if (page===1 && data.applicant_category==='Individual' && data.applicant_subcategory!=='Self'){
            return !data.applicant_subcategory || data.other_applicants.length===0;
        } else {
            return !data.applicant_subcategory;
        }
    }  

    // Check if all fields on page 2 have been filled in.
    // Used to enable/diable Next Page button
    function pg2Complete(data) {
        return  !data.lease_category || !data.district || !data.county || 
                !data.sub_county || !data.parish || !data.village || 
                !data.approximate_size || !data.occupation_of_land;
    }

    // Check if all fields on page 3 have been filled in.
    // Used to enable/diable Next Page button
    function pg3Complete(data) {
        return data.neighbours.length<1;
    }

    const handleChange = e => {
        setData(prevData => ({
            ...prevData,
            [e.target.name]: e.target.value
        }))
    }

    // const updateLease = async () => {
    //     try {    
    //        await axios.put('/application/lease/' + data.id, newLeaseApplication)
    //     } catch (error) {
    //       console.log(error)
    //     }
    // }

    // const postLease = async () => {
    //     try {    
    //        const newLease = await axios.post('/application/lease/', newLeaseApplication)
    //        console.log(newLease)
    //        setData(prevData => ({...prevData,'id': newLease.data.data.results.id}))
    //     } catch (error) {
    //       console.log(error)
    //     }
    // }

    const handleSubmit = e => {
        if(page===0){
            if(data.step==='resume' || data.id){
                newLeaseApplication = {
                    id:data.id, applicant_category: data.applicant_category, dob: data.dob, 
                    citizenship: data.citizenship, gender: data.gender, surname: data.surname,
                    identity_number: data.identity_number, other_names: data.other_names, 
                    marital_status: data.marital_status, phone_number: data.data.phone_number, 
                    email: data.email, security_question: data.security_question, 
                    security_answer: data.security_answer
                }
                // updateLease()
            }else{
                newLeaseApplication = {
                    applicant_category:data.applicant_category, citizenship:data.citizenship,
                    dob:data.dob, identity_number:data.identity_number, surname:data.surname,
                    other_names:data.other_names, gender:data.gender, email:data.email,
                    marital_status:data.marital_status, phone_number:data.phone_number, 
                    security_question:data.security_question, security_answer:data.security_answer                    
                }
                // postLease()
            }
        }else if (page===1){
            newLeaseApplication = {
                id: data.id, applicant_subcategory: data.applicant_subcategory, 
                other_applicants: data.other_applicants, company_name: data.company_name,
                company_registration_number: data.company_registration_number,
                community_name:data.community_name, commnity_location: data.community_location
            }
            // updateLease()
        }else if (page===2){
            newLeaseApplication = {
                id:data.id, lease_category:data.lease_category, district:data.district,
                county:data.county,  sub_county:data.sub_county, parish:data.parish, 
                village:data.village, approximate_size:data.approximate_size, 
                occupation_of_land:data.occupation_of_land 
            }
            // updateLease()
        }else if (page===3){
            newLeaseApplication = {
                id:data.id,
                neighbours:data.neighbours
            }
            // updateLease()
        }else if (page===4){
            newLeaseApplication = {
                id:data.id,
                application_status:'Completed'
            }
            // updateLease()
        }

        console.log(newLeaseApplication)
    }


    const noSubmit = page===4 && (pg0Complete(data) || pg1Complete(data) || pg2Complete(data) || pg3Complete(data))

    const disablePrev = page === 0

    const disableNext =
        (page === Object.keys(title).length - 1)
        || (page === 0 && pg0Complete(data))
        || (page === 1 && pg1Complete(data))
        || (page === 2 && pg2Complete(data))
        || (page === 3 && pg3Complete(data))

    const prevHide = page === 0 && "remove-button"
    const cancelHide = page > 0 && "remove-button"
    const nextHide = page === Object.keys(title).length - 1 && "remove-button"
    const submitHide = page !== Object.keys(title).length - 1 && "remove-button"

    return (
        <FormContext.Provider value={{ 
            title, page, setPage, data, setData, listData, setListData, handleChange, 
            noSubmit, disablePrev,  disableNext, prevHide, nextHide, 
            submitHide, cancelHide, handleSubmit
        }}>
            {children}
        </FormContext.Provider>
    )
}

export default FormContext 