
import { FormProvider } from '../../context/FormContext'
import Form from "../../components/Form"

export default function NewApplication() {
  return (
    <FormProvider>
      <Form />
    </FormProvider>
  )
}
