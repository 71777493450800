import useFormContext from "../../hooks/useFormContext"
import { useState } from "react"
import axios from '../../assets/axios'

const NINREGEX = /^C[MF][A-Z0-9]{12}$/
const EMAILREGEX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
const PHONEREGEX = /^0[2347]\d{8}$/

export default function MoreApplicantDetails() {

  const { data, setData, listData, handleChange } = useFormContext();
  
  // const [submitError, setSubmitError] = useState(false)
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorPhone, setErrorPhone] = useState(false);
  const [errorNIN, setErrorNIN] = useState(false);
  
  const [applicantNumber, setApplicantNumber] = useState(0)
  const [newCitizenship, setNewCitizenship] = useState('')
  const [mainPercentage, setMainPercentage] = useState('')
  const [newOthernames, setNewOthernames] = useState('')
  const [newPercentage, setNewPercentage] = useState('')
  const [expandMain, setExpandMain] = useState(false)
  const [newSurname, setNewSurname] = useState('')
  const [newMarital, setNewMarital] = useState('')
  const [newGender, setNewGender] = useState('')
  const [newPhone, setNewPhone] = useState('')
  const [newEmail, setNewEmail] = useState('')
  const [addApp, setAddApp] = useState(false)
  const [expand, setExpand] = useState(false)
  const [newID, setNewID] = useState('')

  const validateField = (e)=>{
    e.preventDefault();
    if(e.target.name==='newID'){     
      if(NINREGEX.test(e.target.value)){
        fetchNIN(e.target.value)
        setErrorNIN(false)
      }else{
        setErrorNIN(true)
      }
    }else if(e.target.name==='newEmail'){
      if(EMAILREGEX.test(e.target.value)){
        setNewEmail(e.target.value)
        setErrorEmail(false)
      }else{
        setErrorEmail(true)
      }
    }else if(e.target.name==='newPhone'){
      if(PHONEREGEX.test(e.target.value)){
        setNewPhone(e.target.value)
        setErrorPhone(false)
      }else{
        setErrorPhone(true)
      }
    }
  }

  const fetchNIN = async (e) => {
    const NINResult = await axios.get('/application/search-person/?nin=' + e)
    setNewSurname(NINResult.data.data.surname)
    setNewOthernames(NINResult.data.data.given_name)
    setNewGender(NINResult.data.data.gender)
    setNewID(e)
  }

  const handleNewApplicant = (e)=>{
    e.preventDefault();
    if(mainPercentage){
      setData(prevData => ({ ...prevData,'percentage': mainPercentage}));
    }
    
    const newApplicant = { 
      identity_number: newID, citizenship: newCitizenship, surname: newSurname, 
      other_names: newOthernames, gender: newGender,  marital_status: newMarital, 
      phone_number: newPhone, email: newEmail, percentage: newPercentage
    } 

    setData(prevData => ({ ...prevData,'other_applicants': [...data.other_applicants, newApplicant]}));    
    setAddApp(false);
  }

  const deleteOtherApplicant = (e, idx)=>{
    e.preventDefault();
    setData(prevData => ({ ...prevData,'other_applicants': data.other_applicants.splice(idx,1)}));    
  }

  return (
    <>
      {/* Category Display and Subcategory Selection */}
      <div className="row mb-2">
        {/* Category Selected on First Page */}
        <div className="col-lg-6 col-md-6 form-group mb-2">
          Applicant Category Selected
          <input className="form-control px-2 mt-1" type='text' name='applicant_category' value={data.applicant_category} disabled/>
        </div>

        {/* Subcategory Selection */}
        {data.applicant_category ==='Individual' &&
          <div className="col-lg-6 col-md-6 form-group mb-2">
            <label htmlFor='applicant_subcategory'>Applicant Sub-Category</label>
            <select className="form-control px-2 mt-1" name='applicant_subcategory' id='applicant_subcategory' defaultValue={data.applicant_subcategory} onChange={handleChange} required>
              <option>Select Applicant Sub-Category</option>
              <option value='Self'>Self</option>
              <option value='Family'>Family</option>
              <option value='Joint'>Joint Tenants</option>
            </select>
          </div>
        }
      </div>

      {/** Main Applicants Details */}
      <div className="row mb-2">
        <div className='col-lg-12 title mt-2 mb-2'>
          <div className='default'>Main Applicant Details</div>
        </div>
        <div className='row'>
            <div className="col-lg-12 p-2">
              {expandMain ? <i className="bi bi-caret-up" onClick={(e)=>setExpandMain(false)}/> : <i className="bi bi-caret-down"  onClick={(e)=>{setExpandMain(true)}}/>}
              <b>{data.surname} {data.other_names}</b>
              {
                (data.applicant_subcategory === 'Joint' && data.percentage) && 
                  <> - {data.percentage}%</>
              }
            </div>
            {expandMain &&  
              <>
                <div className="col-lg-6 col-md-6 form-group mb-2 px-4">
                  Citizenship<br/>
                  <b>
                    {listData.listCountries.length>0 && listData.listCountries.map((item, idx)=>{
                      return(<span key={item.id}>{ item.id === parseInt(data.citizenship) && item.name}</span>)
                    })}
                  </b>
                </div>
                <div className="col-lg-6 col-md-6 form-group mb-2 px-4">
                  {data.citizenship==='1'? 'NIN' : 'Passport Number'}<br/>
                  <b>{data.identity_number}</b>
                </div>
                <div className="col-lg-6 col-md-6 form-group mb-2 px-4">
                  Gender<br/>
                  <b>{ data.gender }</b>
                </div>
                <div className="col-lg-6 col-md-6 form-group mb-2 px-4">
                  Marital Status<br/>
                  <b>{ data.marital_status}</b>
                </div>
                <div className="col-lg-6 col-md-6 form-group mb-2 px-4">
                  Contact Phone<br/>
                  <b>{ data.phone_number}</b>
                </div>
                <div className="col-lg-6 col-md-6 form-group mb-2 px-4">
                  Contact Address<br/>
                  <b>{ data.email}</b>
                </div>
              </>
            }                  
        </div>
      </div>

      {/* Other Applicants if Family or Joint Tenancy */}
      {(data.applicant_subcategory === 'Joint' || data.applicant_subcategory === 'Family') && 
          <>
            {/** List of additioal Applications. Work for both Family and Joint Tenancy */}
            { data.other_applicants.length>0 && 
              <div className='row'>
                <div className='col-lg-12 title mt-3'>
                  <div className='default'>
                    Other Applicants' Details
                  </div>
                </div>
                {data.other_applicants.map((value, idx)=>{
                  return (
                    <div key={idx} className='mb-2 mx-1'>
                      <div className='row'>
                          <div className="col-lg-12 p-2">
                            {(expand && applicantNumber === idx) ? 
                              <i className="bi bi-caret-up" onClick={(e)=>setExpand(false)}/> 
                              : 
                              <i className="bi bi-caret-down" onClick={(e)=>{setExpand(true); setApplicantNumber(idx)}}/>
                            }
                            <b>{value.surname} {value.othernames}</b>
                            {data.applicant_subcategory === 'Joint' && ' - '+ value.percentage + '%'} &nbsp;&nbsp;
                            <i className="bi bi-pencil-square" aria-label='Edit'/>&nbsp;
                            <i className="bi bi-trash" aria-label='Delete' onClick={(e)=>deleteOtherApplicant(e, idx)}/>
                          </div>
                          {(expand && applicantNumber === idx) && 
                            <>
                              <div className="col-lg-6 col-md-6 form-group mb-2 px-4">
                                Citizenship<br/>
                                <b>
                                  {listData.listCountries.length>0 && listData.listCountries.map((item, idx)=>{
                                    return(<span key={item.id}>{ item.id === parseInt(value.citizenship) && item.name}</span>)
                                  })}
                                </b>
                              </div>
                              <div className="col-lg-6 col-md-6 form-group mb-2 px-4">
                                {value.citizenship==='1'? 'NIN' : 'Passport Number'}<br/>
                                <b>{value.identity_number}</b>
                              </div>
                              <div className="col-lg-6 col-md-6 form-group mb-2 px-4">
                                Surname<br/>
                                <b>{ value.surname }</b>
                              </div>
                              <div className="col-lg-6 col-md-6 form-group mb-2 px-4">
                                Other Names<br/>
                                <b>{ value.other_names }</b>
                              </div>
                              <div className="col-lg-6 col-md-6 form-group mb-2 px-4">
                                Gender<br/>
                                <b>{ value.gender }</b>
                              </div>
                              <div className="col-lg-6 col-md-6 form-group mb-2 px-4">
                                Marital Status<br/>
                                <b>{ value.marital_status}</b>
                              </div>
                              <div className="col-lg-6 col-md-6 form-group mb-4 px-4">
                                Contact Phone<br/>
                                <b>{ value.phone_number}</b>
                              </div>
                              <div className="col-lg-6 col-md-6 form-group mb-4 px-4">
                                Contact Address<br/>
                                <b>{ value.email}</b>
                              </div>
                            </>
                          }                 
                      </div>
                    </div>
                  )
                })}
              </div>
            }

            {/** Add New Applications to Application. Works for both Family and Joint tenancy */}
            { addApp &&
              <div className='row'>
                {(data.applicant_subcategory === 'Joint' && !data.percentage) &&
                  <div className='col-lg-12'>
                    Main Applicant's Percentage
                    <input className="form-control px-2 mt-1" type="text" width='10px' name="percentage" onChange={(e)=>setMainPercentage(e.target.value)} required />
                  </div>
                } 
                <div className="col-lg-6 col-md-6 form-group mb-2">
                  <label htmlFor='newCitizenship'>Other Applicant Citizenship*</label>
                  <select className="form-control px-2 mt-1" name='newCitizenship' id='newCitizenship' onChange={(e)=>setNewCitizenship(e.target.value)} required>
                    <option>Citizen of: </option>
                    {listData.listCountries.length>0 && listData.listCountries.map((cntry, idx) => {
                      return(<option key={cntry.id} value={cntry.id}>{cntry.name}</option>)
                    })}
                  </select> 
                </div>
                {newCitizenship === '1' ? 
                  <div className="col-lg-6 col-md-6 form-group mb-2">
                    Other Applicant NIN*
                    <input className="form-control px-2 mt-1" type="text" name="newID" onChange={validateField} required />
                    {errorNIN && <span><b><i className='bi bi-exclamation-circle'/></b> Mising or incorrect NIN</span>}
                  </div>
                : 
                  <div className="col-lg-6 col-md-6 form-group mb-2">
                    Other Applicant Passport Number*
                    <input className="form-control px-2 mt-1" type="text" name="newID" onChange={(e)=>setNewID(e.target.value)} required />
                  </div>
                }
                <div className="col-lg-6 col-md-6 form-group mb-2">
                  Surname*
                  { newSurname && newCitizenship === '1' ?
                    <input className="form-control px-2 mt-1" type="text" name="newSurname" value={newSurname} disabled/>
                    :
                    <input className="form-control px-2 mt-1" type="text" name="newSurname" onChange={(e) => setNewSurname(e.target.value)}/>
                  }
                </div>
                <div className="col-lg-6 col-md-6 form-group mb-2">
                  Other Names*
                  { newOthernames && newCitizenship === '1' ?
                    <input className="form-control px-2 mt-1" type="text" name="newOthernames" value={newOthernames} disabled/>
                    :
                    <input className="form-control px-2 mt-1" type="text" name="newOthernames" onChange={(e) => setNewOthernames(e.target.value)} required />
                  }
                </div>
                <div className="col-lg-6 col-md-6 form-group mb-2">
                  <label htmlFor='newGender'>Gender*</label>
                  { newCitizenship === '1' ?
                    <input className="form-control px-2 mt-1" type='text' name='newGender' value={newGender} disabled/>
                    :
                    <select className="form-control px-2 mt-1" name='newGender' id='newGender' onChange={(e)=>setNewGender(e.target.value)} required>
                      <option>Select Gender</option>
                      <option value='Female'>Female</option>
                      <option value='Male'>Male</option>
                    </select>  
                  }
                </div>
                <div className="col-lg-6 col-md-6 form-group mb-2">
                  <label htmlFor='newMarital'>Marital Status*</label>
                  <select className="form-control px-2 mt-1" name='newMarital' id='newMarital' onChange={(e)=>setNewMarital(e.target.value)} required>
                    <option>Select Marital Status</option>
                    <option value='Single'>Single</option>
                    <option value='Married'>Married</option>
                    <option value='Widowed'>Widowed</option>
                    <option value='Divorced'>Divorced</option>
                  </select>
                </div>
                <div className="col-lg-6 col-md-6 form-group mb-2">
                  Contact Phone*
                  <input className="form-control px-2 mt-1" type="text" name="newPhone" onChange={validateField} required />
                  {errorPhone && <span><b><i className='bi bi-exclamation-circle'/></b> Mising or incorrect Phone Number. Use format 0 XXX XXX XXX</span>}
                </div>
                <div className="col-lg-6 col-md-6 form-group mb-2">
                  Email Address*
                  <input className="form-control px-2 mt-1" type="text" name="newEmail" onChange={validateField} required />
                  {errorEmail && <span><b><i className='bi bi-exclamation-circle'/></b> Mising or incorrect Email Address</span>}
                </div>
                <div className="col-lg-6 col-md-6 form-group mb-2">
                  &nbsp;
                  {data.whoSubcategory === 'Joint' &&
                    <>
                      Percentage Share*
                      <input className="form-control px-2 mt-1" type="text" name="newPercentage" onChange={(e) => setNewPercentage(e.target.value)} required />
                    </>
                  }
                </div>
                <div className='button-container mb-3 col-lg-6 pt-4 mt-2'>
                  { data.whoSubcategory === 'Joint' ?
                    <button 
                      type="submit" 
                      className={`btn-stage btn-next btn-text button`} 
                      style={{width: '100px'}} 
                      onClick={handleNewApplicant} 
                      disabled={!newID || !newCitizenship || !newSurname || !newOthernames || !newGender || !newMarital || !newPhone || !newEmail || !newPercentage}
                    >
                      Submit Applicant
                    </button>
                  :
                    <button 
                      type="submit" 
                      className={`btn-stage btn-next btn-text button`} 
                      style={{width: '120px'}} 
                      onClick={handleNewApplicant} 
                      disabled={!newID || !newCitizenship || !newSurname || !newOthernames || !newGender || !newMarital || !newPhone || !newEmail}
                    >
                      Submit Applicant
                    </button>
                  }
                </div>
              </div>
            }

            {!addApp &&
              <div className='button-container mb-3 col-lg-12'>
                <button type="button" className={`btn-stage btn-other btn-text button`} style={{width: '100px'}} onClick={(e)=>setAddApp(true)}>Add Applicant</button>
              </div>
            }
          </>
      }

      {/* Communinty Details */}
      { (data.applicant_category=== 'Community' && data.citizenship) && 
        <div className="row mb-2">
          <div className="col-lg-6 col-md-6 form-group mb-2">
            Community Name*
            <input className="form-control px-2 mt-1" type="text" name="commnity_name" value={data.commnity_name} onChange={handleChange} required />
          </div>
          <div className="col-lg-6 col-md-6 form-group mb-2">
            Community Location*
            <input className="form-control px-2 mt-1" type="text" name="community_location" value={data.community_location} onChange={handleChange} required />
          </div>
        </div>
      }

      {/* Company Details */}
      { (data.applicant_category=== 'Company' && data.citizenship) && 
        <div className="row mb-2">
          <div className="col-lg-6 col-md-6 form-group mb-2">
            Company Name*
            <input className="form-control px-2 mt-1" type="text" name="company_name" value={data.company_name} onChange={handleChange} required />
          </div>
          <div className="col-lg-6 col-md-6 form-group mb-2">
            Company Registration Number*
            <input className="form-control px-2 mt-1" type="text" name="company_registration_number" value={data.company_registration_number} onChange={handleChange} required />
          </div>
        </div>
      }
    </>
  )
}
