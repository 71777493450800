import axios from 'axios';

let BASE_URL = 'https://ulc.cml.ug/api';
// if(process.env.NODE_ENV === 'production'){ BASE_URL = 'https://ulc.cml.ug/api'; }

export default axios.create({
    baseURL: BASE_URL
});

export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true
});